<template>
  <div>
    <!--begin::customer-->
    <div>
      <h3 class="card-label">
        {{ $t('treasury_checking.treasury_checking') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5 d-flex">
                <span>{{ $t('treasury_checking.currency') }}: </span>
                <span class="ml-2 mr-2">
                                {{ currency_name }}
                            </span>
              </div>
              <div class="col-lg-3 mb-5">
                <label>{{ $t('treasury_checking.current_amount') }}</label>
                <input type="number" v-model="data.current_amount" :disabled="true" class="form-control" :class="validation && validation.current_amount ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.current_amount" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.current_amount[0] }}
                            </span>
              </div>
              <div class="col-lg-3 mb-5">
                <label>{{ $t('treasury_checking.actual_amount') }}</label>
                <input type="number" v-model="data.actual_amount" class="form-control" :class="validation && validation.actual_amount ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.actual_amount" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.actual_amount[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="col-md-6">-->
        <!--          <div class="card card-custom">-->
        <!--            <div class="card-body row">-->
        <!--              -->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-treasury-checking",
  data() {
    return {
      mainRoute: 'finances/treasury-checking',
      mainRouteDependency: 'base/dependency',

      data: {
        treasury_id: this.$route.query.treasury_id ? this.$route.query.treasury_id : null,
        currency_id: this.$route.query.currency_id ? this.$route.query.currency_id : null,
        currency_code: null,
        current_amount: null,
        actual_amount: null,
        last_validated_date: null,
      },

      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      validation: null,
      currency_name: null,

    };
  },

  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.goBackIndex();
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.goBackIndex();
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },
    goBackIndex() {
      this.$router.push({
        name: 'treasury-checking.index', query: {
          treasury_id: this.$route.query.treasury_id,
        }
      });
    },
    async getData() {

      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.treasury_id = response.data.data.treasury_id;
        this.data.currency_id = response.data.data.currency_id;
        this.data.currency_code = response.data.data.currency_code;
        this.data.current_amount = response.data.data.current_amount;
        this.data.actual_amount = response.data.data.actual_amount;
        this.data.last_validated_date = response.data.data.last_validated_date;
        this.currency_name = response.data.data.currency_name;
      });
    },
    getDataBalance() {
      ApiService.get(`${this.mainRouteDependency}/treasury_balance_by_currency`, {
        params: {
          treasury_id: this.data.treasury_id,
          currency_id: this.data.currency_id,
        }
      }).then((response) => {
        this.data.current_amount = response.data.data.balance;
        this.data.currency_code = response.data.data.currency_code;
        this.currency_name = response.data.data.currency_name;
      });
    },


  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.treasury_checking"), route: '/finances/treasury-checking'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

    if (this.idEditing) {
      this.getData();
    } else {
      this.getDataBalance();
    }

  },
};
</script>